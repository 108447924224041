.table {
  border-collapse: separate;
  border-radius: var(--mdrt-corner-radius-s);
  border-spacing: 0;
  border: 1px solid var(--mdrt-color-borders-dark-02);
  table-layout: fixed;
  width: 100%;

  td,
  th {
    padding: var(--spacing-24) var(--spacing-16);
    text-align: left;
  }

  tr {
    background-color: unset;
  }

  thead {
    th {
      border-bottom: 3px solid var(--mdrt-color-borders-dark-04);
      font: var(--mdrt-title-2);
    }
  }

  tbody {
    td,
    th {
      font: var(--mdrt-body-2);
    }

    tr:not(:last-child) {
      td,
      th {
        border-bottom: 1px solid var(--mdrt-color-borders-dark-02);
      }
    }
  }
}
