@use '@shared/util-styling/mixins';

.menu {
  max-width: calc(var(--container-min-width) - var(--container-spacing-inline) * 2);
  min-width: 216px;
}

.trigger {
  @include mixins.button-icon;
}
